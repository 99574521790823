html {
  scroll-behavior: smooth;
}
html::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Patrick Hand', sans-serif;
  background: silver;
}

.Nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  position: fixed;
  top: 10px;
  width: 100%;
  z-index: 2;
  max-width: 100vw;
}
.navLink {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.3rem;
  height: 40px;
  border-radius: 8px;
  text-decoration: underline;
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
  text-shadow: 0 0 14px white;
  padding: 0;
  padding-bottom: 8px;
  margin: 0;
  background: rgba(0, 0, 0, 0.514);
}
.navLink:hover {
  text-transform: uppercase;
}

.Counter {
  width: 70px;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.185);
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 10px;
  left: 10px;
  border-radius: 8px;
  z-index: 1;
}
.eye {
  color: black;
  margin: 0 5px 0 10px;
}
.count {
  font-weight: bolder;
  margin: 0 10px 0 5px;
}
.countPopup {
  pointer-events: none;
  opacity: 0;
  width: 150px;
  transition: opacity .5s ease-in-out;
  position: absolute;
  top: -65px;
  left: 75px;
  background-color: white;
  color: black;
  display: flex;
  height: 44px;
  font-weight: bolder;
  border-radius: 8px 8px 8px 0px;
}
.Counter:hover .countPopup {
  opacity: 1;
}

.App {
  text-align: center;
}
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}
.transitionGroup {
  position: relative;
}
.routeSection {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}


.home {
  height: 100vh;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 34px rgba(255, 255, 255, 0.705);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(rgb(43, 43, 43),silver 50%,rgb(43, 43, 43)); */
  background-image: radial-gradient(ellipse at 50% 50%,rgb(49, 49, 49), rgb(0, 0, 0) 50%);
}
.hello {
  font-size: 35px;
  font-weight: 400;
}
.name {
  font-size: 40px;
  font-weight: bolder;
}
@keyframes swing
{
    15%
    {
        transform: translateX(5px);
    }
    30%
    {
        transform: translateX(-5px);
    }
    50%
    {
        transform: translateX(3px);
    }
    65%
    {
        transform: translateX(-3px);
    }
    80%
    {
        transform: translateX(2px);
    }
    100%
    {
        transform: translateX(0);
    }
}
.homeLink {
  font-family: sans-serif;
  text-shadow: none;
  color: black;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 74px rgba(255, 255, 255, 0.705);
  border: solid 2px rgb(233, 233, 233);
  border-radius: 5px;
  padding: .2rem .5rem;
}
.homeLink:hover {
  animation: swing 1s ease;
  animation-iteration-count: 1;
  background: black;
  text-shadow: 0 0 14px white;
  color: white;
}


.About {
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* opacity: .5; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  --y: -500%;
  --x: 10%;
  background-image: radial-gradient(circle at var(--x) var(--y),rgb(255, 255, 255) .01%, rgb(10, 10, 10) 1%, black);
  animation: a 4s linear infinite;
  animation-name: y, x;
  animation-duration: 1.5s, 4.5s;
  animation-direction: normal, alternate;
  z-index: 1;
}
.About:after {
  content: '';
  position: absolute;
  /* overflow: hidden; */
  box-shadow: 0px 0px 10px white;
  /* opacity: ; */
  left: var(--x);
  top: var(--y);
  /* right: 0; */
  /* bottom: 0; */
  --y: -800%;
  --x: 10%;
  background: white;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  animation: a 4s linear -2s infinite;
  animation-name: y2, x;
  animation-duration: 5.5s, 13.3s;
  animation-direction: normal, alternate;
  z-index: 1;
}
.About:before {
  content: '';
  position: absolute;
  /* overflow: hidden; */
  box-shadow: 0px 0px 20px white;
  /* opacity: ; */
  left: var(--x);
  top: var(--y);
  /* right: 0; */
  /* bottom: 0; */
  --y: -800%;
  --x: 10%;
  background: white;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  animation: a 4s linear -2s infinite;
  animation-name: y2, x;
  animation-duration: 3.5s, 15.3s;
  animation-direction: normal, alternate;
  z-index: 1;
}
.star {
  content: '';
  position: absolute;
  /* overflow: hidden; */
  box-shadow: 0px 0px 10px white;
  /* opacity: ; */
  left: var(--x);
  top: var(--y);
  /* right: 0; */
  /* bottom: 0; */
  --y: -800%;
  --x: 2%;
  background: white;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  animation: a 4s linear -2s infinite;
  animation-name: y2, x;
  animation-duration: 5.5s, 13.3s;
  animation-direction: normal, alternate;
  z-index: 1;
}
.star1 {
  height: 10px;
  width: 10px;
  left: 3%;
  animation-duration: 3s, 7s;
}
.star2 {
  left: 97%;
  animation-duration: 4.5s, 14.3s;
}
.star3 {
  animation-duration: 5s, 12.8s;
}
@keyframes y {
	to {
    --y: 500%
  }
}
@keyframes y2 {
  to {
    --y: 100%
  }
}
@keyframes x {
	to {
    --x: 98%
  }
}
.aboutContainer {
  overflow: auto;
  height: 100vh;
  width: 100vw;
  padding-top: 80px;
  position: relative;
  z-index: 2;
}
.aboutBackground {
  overflow: auto;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url('./images/space.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .3;
  z-index: 0;
}
.aboutHeader {
  margin-bottom: 0;
  color: white;
  text-shadow: 0px 0px 14px white;
  z-index: 1;
  position: relative;
}
.aboutLink {
  text-decoration: none;
  color: inherit;
}
.iconContainer {
  position: relative;
  z-index: 1;
}
.fa-wave-square {
  margin-bottom: 60px;
  color: white;
}
.aboutCards {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: center;
  z-index: 1;
  animation: flyUp 3s ease-out;
  margin-top: 0px;
  
}
.aboutContainer::-webkit-scrollbar {
  display: none;
}
@keyframes flyUp {
  from {margin-top: 800px;}
  to {margin-top: 0;}
}
.aboutCard {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 9rem;
  height: 411px;
  margin: 5rem 2rem 5rem;
  padding: 70px 20px 10px;
  background-image: linear-gradient(to top, white 1%, rgb(24, 24, 24) 2%, gray 10%, rgb(223, 223, 223) 30%);
  animation: flash .005s ease-in-out infinite;
  position: relative;
  border-radius: 80% 80% 60% 60%/120% 120% 30% 30%;
  z-index: 9;
  top: 0px;
  bottom: 0;
  transition: top 1s;
  transition-timing-function: ease-in-out;
}
.aboutCard:hover {
  top: -100px;
}
@keyframes flash {
  0% {
    background-image: linear-gradient(to top, white 1%, rgb(24, 24, 24) 2%, gray 10%, rgb(223, 223, 223) 30%)
  }
  50% {
    background-image: linear-gradient(to top, white 1.5%, rgb(24, 24, 24) 2.5%, gray 10.5%, rgb(223, 223, 223) 30%)
  }
  100% {
    background-image: linear-gradient(to top, white 1%, rgb(24, 24, 24) 2%, gray 10%, rgb(223, 223, 223) 30%)
  }
}
.aboutCard:before,
.aboutCard:after {
  content: '';
  position: absolute;
  border-bottom: 70px solid rgb(204, 204, 204);
  border-image: linear-gradient(to top, white 40%, orange, red 120%);
  /* border-left: 10px solid; */
  border-right: 45px solid;
  top: 486px;
  right: 67px;
  transform: rotate(-175deg);
  animation: thrust1 .05s ease-in-out infinite;
  z-index: 1;
  box-shadow: 0px 0 60px white;
  opacity: .6;
}
.aboutCard:after {
  right: auto;
  left: 67px;
  transform: rotate(175deg);
}
.flameTip {
  width: 0; 
  height: 0; 
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 10px solid rgb(255, 38, 0);
  position: absolute;
  bottom: -72px;
  opacity: .6;
  animation: thrust2 .07s ease-in-out infinite;
}
@keyframes thrust2 {
  0% {
    transform: rotate(10deg);
    bottom: -72;
  }
  50% {
    transform: rotate(0deg);
    bottom: -76px
  }
  100% {
    transform: rotate(-10deg);
    bottom: -72;
  }
}
@keyframes thrust1 {
  0% {
    border-right: 45px solid;
    top: 486px
  }
  50% {
    border-right: 35px solid;
    top: 490px
  }
  100% {
    border-right: 45px solid;
    top: 486px
  }
}

.iconWrapper {
  --y: -500%;
  --x: 10%;
  background: radial-gradient(circle at var(--x) var(--y), rgb(150, 150, 150) 3%, rgb(99, 99, 99) 1%,black 88%);
  color: rgb(0, 119, 255);
  width: 6rem;
  height: 5.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  animation: y 4s ease-in-out infinite;
  animation-name: y, x;
  animation-duration: 2.2s, 3.5s;
  animation-direction: normal, alternate;
}
.aboutP {
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 20px;
}
.aboutH2 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
@keyframes bounce
{
    15%
    {
        transform: translateY(-6px);
    }
    30%
    {
        transform: translateY(0px);
    }
    50%
    {
        transform: translateY(-6px);
    }
    65%
    {
        transform: translateY(0px);
    }
    80%
    {
        transform: translateY(-6px);
    }
    100%
    {
        transform: translateY(0);
    }
}
.pointUp {
  position: absolute;
  top: 55px;
  right: 28px;
  font-size: 30px;
  color: darkgreen;
  z-index: 1;
  animation: bounce 2s linear 0s infinite;
}
.projectLinkContainer {
  position: relative;
}

/* ---------------------Profile----------------- */
.routeSection {
  background: black;
  height: 100vh;
  /* overflow: auto; */
}
.routeSection::-webkit-scrollbar {
  display: none;
}
.Profile::-webkit-scrollbar {
  display: none;
}
.Profile {
  background: black;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: visible;
  height: auto;
  width: 100%;
  margin-top: 80px;
  padding-bottom: 20px;
}
.skillsHeader {
  margin-bottom: 0;
  margin-top: 100px;
  color: white;
  text-shadow: 0px 0px 14px silver;
}
.infoContainer {
  display: flex;
  box-sizing: border-box;
  overflow: visible;
  flex-flow: row wrap;
  max-width: 1250px;
  width: 100%;
  height: auto;
  justify-content: space-evenly;
  align-items: flex-start;
  color: white;
  font-size: 24px;
  padding: 0 20px
  /* position: relative; */
}
.Me {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 270px;
  width: 500px;
  padding-bottom: 20px;
  position: relative;
}
.bioHeader {
  padding-bottom: 30px;
}
.backInFive {
  position: absolute;
  background: white;
  color: black;
  padding: 0px 15px 20px;
  margin-top: 0px;
  z-index: 0;
  top: 31%;
  transform: rotate(-15deg);
}
.meContainer {
  position: relative;
  width: 198px;
  height: 300;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  padding-top: 2px;
}
.meContainer:before { 
  content: ""; 
  position: absolute;  
  top: -40px;
  left: 8px; 
  border-bottom: 72px solid transparent; 
  border-left: 69px solid black; 
  border-right: 70px solid black; 
  width: 45px;
  height: 0px; 
} 
.meContainer:after { 
  content: ""; 
  position: absolute; 
  bottom: -35px; 
  left: 8px; 
  border-top: 70px solid transparent; 
  border-left: 69px solid black; 
  border-right: 70px solid black; 
  width: 45px; 
  height: 0px; 
}
.meWrapper {
  transform: rotate();
  top: 0;
  left: 0px;
  position: relative;
  animation: skid 4s ease-out;
}
@keyframes skid {
  0% {
    transform: rotate(-45deg);
    left: -1800px;
  }
  25% {
    left: -1800px;
  }
  60% {
    transform: rotate(-45deg);
  }
  65% {
    transform: rotate(-45deg);
    top: 0;
  }
  67.5% {
    transform: rotate(0deg);
    top: -25px;
  }
  70% {
    transform: rotate(35deg);
    top: -6px;
  }
  72% {
    transform: rotate(35deg);
    top: -14px;
  }
  74% {
    transform: rotate(35deg);
    top: -6px;
  }
  76% {
    transform: rotate(35deg);
    top: -14px;
  }
  78% {
    transform: rotate(35deg);
    top: -6px;
  }
  85% {
    transform: rotate(40deg);
    top: -6px
  }
  93% {
    transform: rotate(40deg);
    left: 90px;
    top: -6px;
  }
  96.5% {
    top: -6px;
  }
  100% {
    transform: rotate(0deg);
    left: 0;
    top: 0;
  }
}
.bioTextContainer {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.bioName {
  animation: fade2 5.5s linear;
  margin: 15px 0 0 0;
  text-decoration: underline;
  text-decoration-color: rgb(119, 174, 255);
}
.bioText {
  padding: 20px 10px;
  animation: fade2 6s linear;
  text-shadow: 0 0 14px rgb(155, 155, 155);
}
.bioLinks {
  animation: fade2 6.5s linear;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  justify-self: flex-start;
  margin-bottom: 20px;
}
.bioLink {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
}
.bioLine {
  padding-left: 15px;
}
.bioLineLink {
  width: 100%;
  margin-left: 10px;
  font-size: 22px;
  color: rgb(119, 174, 255);
}
.lambda {
  height: 17px;
  width: 18px;
  padding-right: 0px;
  margin: 0;
}
.lambdaLink {
  text-decoration: none;
  color: white;
}
.lambdaLink:hover {
  color: rgb(163, 0, 0);
}
.bioValues {
  text-decoration: underline;
  text-decoration-color: white;
  color: rgb(119, 174, 255);
  padding: 0 5px 0 5px;
}
.bioValues:hover {
  box-sizing: border-box;
  color: lightblue;

}
.bioContact {
  text-decoration: underline;
  text-decoration-color: white;
  color: rgb(119, 174, 255);  padding: 0 5px 0 5px;
}
.bioContact:hover {
  box-sizing: border-box;
  color: lightblue;

}

.networksContainer {
  position: fixed;
  bottom: 5px;
  left: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  font-size: 18px;
  z-index: 1;
}
.myGitLink {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.199);
}
.myGitLink:hover {
  color: rgba(255, 255, 255, 0.281);
}
.myLinkedInLink {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.199);
}
.myLinkedInLink:hover {
  color: rgba(255, 255, 255, 0.281);
}
.myResumeLink {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.199);
}
.myResumeLink:hover {
  color: rgba(255, 255, 255, 0.281);
}

.Resume {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 50px;
}
canvas {
  animation: fade 2s ease-in-out;
  opacity: .9;
  border-radius: 18px;
  box-shadow: 0 0 30px 10px white;
}
.pdfPageNums {
  color: white;
  margin: 0px;
  position: absolute;
  bottom: 80px;
}
.resumeDownloadForm {
  animation: fade 3s ease-in-out;
  position: fixed;
  left: 50%;
  top: 170px;
  margin-left: -100px;
}
.downloadButton {
  width: 200px;
  height: 45px;
  background-color: black;
  color: white;
  border-radius: 18px;
  opacity: .3;
  outline: none;
}
.downloadButton:hover {
  opacity: 1;
}

@media (max-width: 1040px) {
  .techStack {
    order: 2;
  }
  .Me {
    order: 1;
  }
  .bioLinks {
    order: 3;
  }
  .infoContainer {
    flex-flow: column;
    align-items: center;
  }
  .bioLinks {
    margin-top: 40px;
  }
}
.techStack {
  width: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.techContainer {
  width: 100%;
  flex-flow: row wrap;
  display: flex;
  opacity: 1;
  justify-content: center;
  animation: fade 5s linear;
  border: 1px solid rgba(255, 255, 255, 0.075);
  box-shadow: inset 0 0 14px rgba(192, 192, 192, 0.24);
  border-radius: 28px;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade2 {
  from {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.frontContainer {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 250px;
}
.backContainer {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 250px;
}
.techHeader {
  margin-bottom: 20px;
  color: white;
  text-shadow: 0px 0px 10px white;
  text-decoration: underline;
  align-self: center;
  font-size: 24px;
}
.stackHeader {
  font-size: 28px;
  color: rgb(119, 174, 255);
  text-decoration: none;
  text-shadow: 0px 0px 14px white;
}
.languageContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.techName {
  width: 100%;
  color: white;
  text-shadow: 0px 0px 8px white;
  font-size: 23px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.techIcon {
  color: white;
  font-size: 40px;
  font-weight: 100;
  height: 45px;
  width: 45px;
  padding-right: 15px;
}
.redux {
  height: 35px;
  width: 40px;
}
.js {
  padding-left: 3.5px;
  padding-right: 18px;
}
.html {
  font-size: 32.3px;
}
.py {
  padding-left: 0px;
  padding-right: 16px;
}
.sqlite {
  width: 35px;
  height: 40px;
  padding-right: 15px;
}
.knex {
  background-color: black;
  width: 42px;
  height: 42px;
  margin-left: -4px;
  padding-right: 12px;
}


/*************************** Projects *******************************/


.projectsContainer {
  height: 100%;
  width: auto;
  margin: 0 20px 0 20px;
  background-color: rgba(255, 255, 255, 0.144);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px 2px white;
  border-radius: 30px;
}
.projectWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 10px;
  animation: fade2 1.25s ease-out;
}

.Project {
  position: relative;
  margin: 1rem 1rem 1rem 1rem;
  height: 18rem;
  width: 18rem;
}
.Project:hover {
  border-radius: 12px;
  box-shadow: 0px 0px 15px 0px white;
}
.projectTitle {
  position: absolute;
  top: 35%;
  width: 100%;
  color: white;
  font-size: 3.5em;
  opacity: .9;
}
.projectImg {
  height: 18rem;
  width: 18rem;
  border-radius: 10px;
}
.hoverContainer {
  margin: 1rem 1rem 1rem 1rem;
  height: 18rem;
  width: 18rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.753);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px 2px white;
}

.hoveredProject {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  width: 100%;
}
.projectHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  animation: fade .5s ease-in-out;
  border-bottom: 1px solid black;
}
.projectName {
  margin: 0;
}
.projectDate {
  margin: 0;
  padding-top: 6px;
}
.projectStack {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  animation: fade 1s ease-in;
}
.projectStackElement {
  margin-bottom: 0px;
  margin-top: 10px;
  color: rgb(0, 132, 255);
  font-weight: bolder;
}
.projectDescription {
  font-weight: bold;
  animation: fade 2s ease-in;
  margin-top: 5px;
  margin-bottom: 10px;
}
.gitIcon {
  font-size: larger;
}
.projectPoint {
  margin-left: 3px;
}
.projectLinks {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.deployedTo {
  margin-top: 5px;
  margin-bottom: 5px;
  animation: fade 3.5s linear;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.deployLink {
  text-decoration: none;
  margin-left: 5px;
}
.deployLink:hover {
  text-decoration: underline;
}
.gitDiv {
  display: flex;
  animation: fade 3.5s linear;
}
.repoLink {
  text-decoration: none;
  margin: 0;
  text-align: left;
  margin-left: 5px;
}
.repoLink:hover {
  text-decoration: underline;
}
.projectPointRight {
  font-size: 22px;
  color: white;
}
.projectLink {
  display: flex;
  align-items: center;
  margin-top: 50px;
  animation-delay: 10s;
  animation: fade 2s ease-in-out;
}
.projectContact {
  text-decoration: none;
  padding: 0 20px 0 20px;
}
.projectContact:hover {
  color: inherit;
  border-radius: 15px;
  box-shadow: 0 0 20px .1px white inset;
}

.singleProject {
  position: relative;
}
.closeButton {
  position: absolute;
  bottom: 19px;
  right: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  border-radius: 8px;
  border-width: 1px;
  outline: none;
}
.closeButton:hover {
  background-color: black;
  color: white;
}

.Contact {
  margin-top: 80px;
  padding-bottom: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.contactForm {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  height: 560px;
  max-width: 800px;
  width: 60%;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.075);
  box-shadow: inset 0 0 14px rgba(192, 192, 192, 0.24);
  border-radius: 28px;
  color: white;
}
#contactDiv {
  display: flex;
  flex-flow: column;
}
.contactThankyou {
  color: white;
  font-size: 20px;
}
label {
  font-size: larger;
}
input {
  font-size: larger;
  border-radius: 5px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.226);
  color: rgb(22, 179, 231);
  padding: 4px;
  text-shadow: 0px 0px 10px white;
  border: none;
  text-align: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid white;
  -webkit-text-fill-color: rgb(22, 179, 231);
  -webkit-box-shadow: 0 0 0px 1000px rgb(59, 59, 59) inset;
}
@keyframes fromLeft1 {
  0% { transform: translateX(-2000px) }
  30% { transform: translateX(0); }
}
@keyframes fromLeft2 {
  0% { transform: translateX(-2000px) }
  20% { transform: translateX(-2000px) }
  70% { transform: translateX(0); }
}
@keyframes fromLeft3 {
  0% { transform: translateX(-2000px) }
  50% { transform: translateX(-2000px) }
  100% { transform: translateX(0); }
}
@keyframes fromRight1 {
  0% { transform: translateX(2000px) }
  /* 10% { transform: translateX(2000px) } */
  45% { transform: translateX(0); }
}
@keyframes fromRight2 {
  0% { transform: translateX(2000px) }
  30% { transform: translateX(2000px) }
  75% { transform: translateX(0); }
}
@keyframes fromBottom {
  0% { transform: translateY(2000px) }
  60% { transform: translateY(2000px) }
  100% { transform: translateY(0); }
}
.contactName {
  animation: 3s ease-in-out 0s 1 fromLeft1
}
.contactNameInput {
  border: 1px solid white;
}
.contactCompany {
  animation: 3s ease-in-out 0s 1 fromRight1
}
.contactCompanyInput {
  border: 1px solid white;
}
.contactEmail {
  animation: 2.5s ease-in-out 0s 1 fromLeft2;
}
.contactEmailInput::placeholder {
  color: white;
  opacity: .3;
}
.contactEmailInput {
  border: 1px solid white;
}
.contactPhone {
  animation: 3s ease-in-out 0s 1 fromRight2
}
.contactPhoneInput::placeholder {
  color: white;
  opacity: .3;
}
.contactPhoneInput {
  border: 1px solid white;
}
.contactBody {
  animation: 2.8s ease-in-out 0s 1 fromLeft3;
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.contactBodyTextarea {
  font-size: larger;
  border-radius: 5px;
  width: 100%;
  height: 200px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.226);
  color: rgb(22, 179, 231);
  text-shadow: 0px 0px 5px white;
}
.contactBodyTextarea::placeholder {
  color: white;
  opacity: .3;
}
.contactBodyTextarea::-webkit-scrollbar {
  display: none;
}
.contactButton {
  animation: 3.2s ease-in-out 0s 1 fromBottom;
  background-color: rgba(255, 255, 255, 0.226);
  border: none;
  color: rgb(47, 193, 241);
  text-shadow: 0px 0px 5px white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px 5px white;
  margin-top: 20px;
}
.contactButton:hover {
  box-shadow: 0 0 10px 1px white;
  color: rgb(110, 196, 224);
}
.contactThankyou {
  animation: fade2 4.45s ease-in-out;
}